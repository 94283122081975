/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider.state('move-money.savings-transfer', {
    url: '/savings-transfer',
    templateUrl: 'apps/savings-transfer/templates/acp-savings-transfer.ng.html',
    resolve: {
      module: /* @ngInject */ function($ocLazyLoad) {
        return import(/* webpackChunkName: "acp.section.savings-transfer" */ 'apps/savings-transfer').then(
          $ocLazyLoad.loadModule
        );
      }
    }
  });
}

export default states;
